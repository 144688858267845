<template>
  <div>
    <b-modal
      hide-footer
      id="modal-standard-extra"
      :title="menu_item_extra_title"
      title-class="font-18"
      ref="modal"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <div class="form-group">
          <label>Select Extra Type</label>
          <div>
            <select
              v-model="menu_extra_type_id"
              required
              class="form-control-role input-style"
            >
              <option disabled value="">Select Extra Type</option>
              <option
                v-for="extra in menu_extra_types"
                :value="extra.uuid"
                v-bind:key="extra.uuid"
                :title="extra.extra_type"
              >
                {{ extra.extra_type }}
              </option>
            </select>
          </div>
        </div>
        <b-form-group
          label="Extra Name"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <b-form-input
            id=""
            class="input-style"
            v-model="extra_name"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Price"
          label-for="name-input"
          invalid-feedback="Price is required"
        >
          <b-form-input
            type="number"
            class="input-style"
            id=""
            v-model="extra_price"
            min="1"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Quantity"
          label-for="name-input"
          invalid-feedback="Quantity is required"
        >
          <b-form-input
            type="number"
            id=""
            class="input-style"
            v-model="extra_quantity"
            min="1"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Description" label-for="comment-input">
          <b-form-textarea id="" v-model="description"></b-form-textarea>
        </b-form-group>
        <div class="text-right">
          <b-button
            type="button"
            class="mt-3 btn-orange"
            :disabled="isValidate"
            @click="handleOk"
            >Add</b-button
          >
        </div>
      </form>
    </b-modal>
  </div>
</template>

<style scoped></style>
<script>
import ApiService from "@/core/services/api.service";
// import XLSX from "xlsx";
import axios from "axios";
import helpers from "../../../helpers";

export default {
  name: "MenuItemModal",
  data() {
    return {
      restaurantUuid: "",
      menu_extra_types: [],
      menu_extra_item_id: "",
      menu_extra_type_id: "",
      extra_name: "",
      extra_price: "",
      extra_quantity: "",
      description: "",
      is_edit: false,
      loader: false
    };
  },
  props: {
    menu_item_extra_title: {
      type: String
    },
    obj_menu_item_extra: {
      type: Object
    },
    is_edit_item_extra_modal: {
      type: Boolean
    },
    menu_item_uuid: {
      type: String
    }
  },
  watch: {
    obj_menu_item_extra() {
      this.updateMenuExtraType();
    }
  },
  components: {},
  computed: {
    isValidate() {
      return !(
        this.menu_extra_type_id !== "" &&
        this.extra_name !== "" &&
        this.extra_price !== "" &&
        this.extra_quantity !== "" &&
        this.description !== ""
      );
    }
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.itemExtraState = valid;
      return valid;
    },
    resetModal() {},
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      // Trigger submit handler
      let URL = "";
      let toastMessage = "";
      let axiosCall = "";
      let formData = new FormData();
      if (this.is_edit_item_extra_modal) {
        URL =
          this.$path +
          "/admin/menu/extra/item/" +
          this.menu_item_uuid +
          "/" +
          this.menu_extra_item_id;
        toastMessage = "Menu Item Extra Updated!";
        formData.append("menu_extra_item_uuid", this.menu_extra_item_id);
      } else {
        URL = this.$path + "/admin/menu/extra/item/" + this.menu_item_uuid;
        toastMessage = "Menu Item Extra Added";
      }
      formData.append("menu_extra_type_uuid", this.menu_extra_type_id);
      formData.append("extra_name", this.extra_name);
      formData.append("extra_price", this.extra_price);
      formData.append("extra_quantity", this.extra_quantity);
      formData.append("description", this.description);

      if (this.is_edit_item_extra_modal) {
        axiosCall = axios.patch(URL, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
      } else {
        axiosCall = ApiService.post(URL, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
      }

      axiosCall
        .then(data => {
          if (this.is_edit_item_extra_modal) {
            this.$emit("update_item_extra", data.data.data);
          } else {
            this.$emit("add_item_extra", data.data.data);
          }
          this.$bvToast.toast(toastMessage, {
            title: "Success",
            variant: "success",
            solid: true,
            toaster: "b-toaster-top-center"
          });
          this.handleSubmit();
          this.is_edit = false;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });

      // Prevent modal from closing
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-standard-extra");
      });
    },
    getExtra() {
      axios
        .get(
          this.$path +
            "/admin/menu/extra/type/restaurant/" +
            this.restaurantUuid
        )
        .then(({ data }) => {
          this.menu_extra_types = data.data;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    updateMenuExtraType() {
      if (this.obj_menu_item_extra) {
        this.menu_extra_item_id = this.obj_menu_item_extra.uuid;
        if (this.obj_menu_item_extra.extra_type) {
          this.menu_extra_type_id = this.obj_menu_item_extra.extra_type.uuid;
        }
        this.extra_name = this.obj_menu_item_extra.extra_name;
        this.extra_price = this.obj_menu_item_extra.extra_price;
        this.extra_quantity = this.obj_menu_item_extra.extra_quantity;
        this.description = this.obj_menu_item_extra.description;
      }
    }
  },
  mounted() {
    this.updateMenuExtraType();
    this.restaurantData = JSON.parse(localStorage.getItem("restaurantDetails"));
    this.restaurantUuid = this.restaurantData.uuid;
    this.getExtra();
  }
};
</script>

<style scoped></style>
