<template>
  <div>
    <b-modal
      hide-footer
      id="modal-standard-category"
      :title="menu_category_title"
      title-class="font-18"
      ref="modal"
      @show="resetModal"
      @hidden="resetModal"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <div>
          <b-form-group
            label="Menu Category Name"
            label-for="name-input"
            invalid-feedback="Name is required"
          >
            <b-form-input
              id="name-input"
              v-model="menu_category_name"
              class="input-style"
              required
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="form-group">
          <label>Serving</label>
          <div>
            <select
              v-model="serving_id"
              required
              name="serving_id"
              class="form-control-role input-style"
            >
              <option value="">Select Serving</option>
              <option
                v-for="serving in servings"
                :value="serving.id"
                v-bind:key="serving.id"
                :title="serving.serving"
              >
                {{ serving.serving }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label>Course</label>
          <div>
            <select
              v-model="course_id"
              required
              name="course_id"
              class="form-control-role input-style"
            >
              <option value="">Select Course</option>
              <option
                v-for="course in courses"
                :value="course.id"
                v-bind:key="course.id"
                :title="course.course_type"
              >
                {{ course.course_type }}
              </option>
            </select>
          </div>
        </div>
        <div>
          <b-form-group
            label="Menu Category Desc"
            label-for="description-input"
          >
            <b-form-textarea
              id="description-input"
              v-model="menu_category_desc"
            ></b-form-textarea>
          </b-form-group>
        </div>
        <div class="text-right">
          <b-button
            type="button"
            class="mt-3 btn-orange"
            :disabled="isCategoryValidate"
            @click="handleOk"
            >Add</b-button
          >
        </div>
      </form>
    </b-modal>
  </div>
</template>

<style scoped></style>
<script>
import ApiService from "@/core/services/api.service";
import helpers from "../../../helpers";
// import XLSX from "xlsx";
import axios from "axios";

export default {
  name: "MenuCategoryModal",
  data() {
    return {
      menuCategoryNameState: null,
      menuCategoryDescState: null,
      courses: [],
      servings: [],
      menuGroup: [],
      menu_category_name: "",
      course_id: "",
      serving_id: "",
      menu_category_desc: "",
      restaurantUuid: "",
      loader: false
    };
  },
  props: {
    menu_category_title: {
      type: String
    },
    obj_menu_category: {
      type: Object
    },
    is_edit_category_modal: {
      type: Boolean
    },
    menu_group_uuid: {
      type: String
    }
  },
  watch: {
    obj_menu_category() {
      this.updateMenuCategory();
    }
  },
  components: {},
  computed: {
    isCategoryValidate() {
      return !(
        this.menu_category_name !== "" &&
        this.serving_id !== "" &&
        this.course_id !== ""
      );
    }
  },

  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.menuCategoryNameState = valid;
      this.menuCategoryDescState = valid;
      return valid;
    },
    resetModal() {
      this.menuCategoryNameState = null;
      this.menuCategoryDescState = null;
    },
    handleOk(bvModalEvt) {
      this.loader = true;
      bvModalEvt.preventDefault();
      // Trigger submit handler
      let obj = {
        menu_name: this.menu_category_name,
        restaurant_uuid: this.restaurantUuid,
        description: this.menu_category_desc,
        serving_id: this.serving_id,
        course_id: this.course_id
      };
      this.is_edit_category_modal
        ? this.updateMenuCategoryApi(obj)
        : this.addMenuCategoryApi(obj);
    },
    addMenuCategoryApi(obj) {
      ApiService.post(
        this.$path + "/admin/menu/category/group/" + this.menu_group_uuid,
        obj
      )
        .then(response => {
          this.$emit("add_category", response.data.data);
          helpers.successMessages(this, response.data.message);
          this.handleSubmit();
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    updateMenuCategoryApi(obj) {
      ApiService.patch(
        this.$path +
          "/admin/menu/category/group/" +
          this.menu_group_uuid +
          "/" +
          this.menu_category_uuid,
        obj
      )
        .then(response => {
          this.$emit("update_category", response.data.data);
          this.handleSubmit();
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-standard-category");
      });
    },
    getServings() {
      axios
        .get(this.$path + "/admin/platform/serving")
        .then(response => {
          this.servings = response.data.data;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    getCourses() {
      axios
        .get(this.$path + "/admin/platform/courses")
        .then(response => {
          this.courses = response.data.data;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    updateMenuCategory() {
      if (this.obj_menu_category) {
        this.menu_category_uuid = this.obj_menu_category.uuid;
        this.menu_category_name = this.obj_menu_category.menu_category_name;
        if (this.obj_menu_category.serving) {
          this.serving_id = this.obj_menu_category.serving.id;
        }
        if (this.obj_menu_category.course_type) {
          this.course_id = this.obj_menu_category.course_type.id;
        }
        this.menu_category_desc = this.obj_menu_category.description;
      }
    }
  },
  mounted() {
    this.updateMenuCategory();
    this.restaurantData = JSON.parse(localStorage.getItem("restaurantDetails"));
    this.restaurantUuid = this.restaurantData.uuid;
    this.getServings();
    this.getCourses();
  }
};
</script>

<style scoped></style>
