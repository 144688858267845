<template>
  <div>
    <b-modal
      hide-footer
      id="modal-standard-item"
      :title="menu_item_title"
      title-class="font-18"
      ref="modal"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Select Menu Item Types</label>
              <div>
                <select
                  v-model="menu_item_type_uuid"
                  required
                  class="form-control-role input-style"
                >
                  <option disabled value="">Select Menu Item Types</option>
                  <option
                    v-for="item_type in menu_item_types"
                    :value="item_type.uuid"
                    v-bind:key="item_type.uuid + '_' + Math.random()"
                    :title="item_type.menu_item_type"
                  >
                    {{ item_type.menu_item_type }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Item Name"
              label-for="name-input"
              invalid-feedback="Name is required"
            >
              <b-form-input
                id=""
                class="input-style"
                v-model="item_name"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Price"
              label-for="name-input"
              invalid-feedback="Price is required"
            >
              <b-form-input
                type="number"
                id=""
                class="input-style"
                v-model="item_price"
                min="1"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Quantity"
              label-for="name-input"
              invalid-feedback="Quantity is required"
            >
              <b-form-input
                type="number"
                id=""
                class="input-style"
                v-model="item_quantity"
                min="1"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Stock"
              label-for="name-input"
              invalid-feedback="Stock is required"
            >
              <b-form-input
                type="number"
                id=""
                class="input-style"
                min="1"
                v-model="daily_stock"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Topping Limit"
              label-for="name-input"
              invalid-feedback="Topping Limit is required"
            >
              <b-form-input
                type="number"
                id=""
                class="input-style"
                min="1"
                v-model="topping_limit"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-12">
            <div>
              <b-form-group label="Is Generic">
                <div class="d-flex align-items-center">
                  <b-form-radio v-model="is_generic" name="is_generic" value="1"
                    >Yes
                  </b-form-radio>
                  <b-form-radio
                    v-model="is_generic"
                    name="is_generic"
                    class="ml-3"
                    value="0"
                    >No
                  </b-form-radio>
                </div>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-12">
            <div>
              <b-form-group
                label="File"
                label-for="document-input"
                invalid-feedback="Menu Item image is required"
              >
                <b-form-file
                  id="document-input"
                  v-model="item_image"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  accept=".png, .jpg, .jpeg"
                  @change="onFileChange"
                ></b-form-file>
              </b-form-group>
            </div>
          </div>
        </div>
        <b-form-group label="Description" label-for="comment-input">
          <b-form-textarea
            id="description-input"
            v-model="description"
          ></b-form-textarea>
        </b-form-group>
        <div class="text-right">
          <b-button
            type="button"
            class="mt-3 btn-orange"
            :disabled="isValidate"
            @click="handleOk"
            >Add</b-button
          >
        </div>
      </form>
    </b-modal>
  </div>
</template>

<style scoped></style>
<script>
import ApiService from "@/core/services/api.service";
import helpers from "../../../helpers";
// import XLSX from "xlsx";
import axios from "axios";

export default {
  name: "MenuItemModal",
  data() {
    return {
      restaurantUuid: "",
      menu_item_types: [],
      edit_menu: {},
      menu_item_uuid: "",
      menu_item_type_uuid: "",
      item_name: "",
      item_image: null,
      item_price: "",
      item_quantity: "",
      daily_stock: "",
      description: "",
      is_generic: "",
      topping_limit: "",
      is_edit: false,
      loader: false
    };
  },
  props: {
    menu_item_title: {
      type: String
    },
    obj_menu_item: {
      type: Object
    },
    is_edit_item_modal: {
      type: Boolean
    },
    menu_category_uuid: {
      type: String
    }
  },
  watch: {
    obj_menu_item() {
      this.updateMenuItems();
    }
  },
  components: {},
  computed: {
    isValidate() {
      return !(
        this.menu_item_type_uuid !== "" &&
        this.item_name !== "" &&
        this.item_price !== "" &&
        this.item_quantity !== "" &&
        this.daily_stock !== "" &&
        this.is_generic !== "" &&
        this.topping_limit !== ""
      );
    }
  },

  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.menuItemState = valid;
      return valid;
    },
    resetModal() {
      this.item_image = null;
    },
    handleOk(bvModalEvt) {
      this.loader = true;
      bvModalEvt.preventDefault();
      // Trigger submit handler
      let URL = "";
      let formData = new FormData();
      formData.append("menu_item_type_uuid", this.menu_item_type_uuid);
      formData.append("item_name", this.item_name);
      formData.append("src", this.item_image ? this.item_image : "");
      formData.append("item_price", this.item_price);
      formData.append("item_quantity", this.item_quantity);
      formData.append("daily_stock", this.daily_stock);
      formData.append("description", this.description ?? "");
      formData.append("is_generic", this.is_generic);
      formData.append("topping_limit", this.topping_limit);
      if (this.is_edit_item_modal) {
        URL =
          this.$path +
          "/admin/menu/items/category/" +
          this.menu_category_uuid +
          "/" +
          this.menu_item_uuid;
        this.updateMenuItemsApi(URL, formData);
      } else {
        URL =
          this.$path + "/admin/menu/items/category/" + this.menu_category_uuid;
        this.addMenuItemsApi(URL, formData);
      }
    },
    addMenuItemsApi(URL, formData) {
      ApiService.post(URL, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(response => {
          this.handleSubmit();
          this.$emit("add_item", response.data.data);
          helpers.successMessages(this, response.data.message);
          this.is_edit = false;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    updateMenuItemsApi(URL, formData) {
      ApiService.post(URL, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(response => {
          this.handleSubmit();
          this.$emit("update_item", response.data.data);
          helpers.successMessages(this, response.data.message);
          this.is_edit = false;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-standard-item");
      });
    },
    getMenuItemType() {
      axios
        .get(this.$path + "/admin/menu/items/type")
        .then(response => {
          this.menu_item_types = response.data.data;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    updateMenuItems() {
      if (this.obj_menu_item) {
        this.menu_item_uuid = this.obj_menu_item.uuid;
        if (this.obj_menu_item.menu_item_type) {
          this.menu_item_type_uuid = this.obj_menu_item.menu_item_type.uuid;
        }
        this.item_name = this.obj_menu_item.item_name;
        this.item_price = this.obj_menu_item.item_price;
        this.item_quantity = this.obj_menu_item.item_quantity;
        this.daily_stock = this.obj_menu_item.daily_stock;
        this.description = this.obj_menu_item.description;
        this.is_generic = this.obj_menu_item.is_generic;
        this.topping_limit = this.obj_menu_item.topping_limit;
      }
    },
    onFileChange(e) {
      this.item_image = e.target.files[0];
    }
  },
  mounted() {
    this.updateMenuItems();
    this.restaurantData = JSON.parse(localStorage.getItem("restaurantDetails"));
    this.restaurantUuid = this.restaurantData.uuid;
    this.getMenuItemType();
  }
};
</script>

<style scoped></style>
