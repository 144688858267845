<template>
  <div>
    <h4 class="card-label border-bottom mb-3">Menus</h4>
    <div class="loader-wrapper" v-if="loader">
      <div class="image-loader"></div>
    </div>
    <div class="form-group">
      <div class="card-body">
        <div class="row">
          <div class="text-center">
            <b-button
              @ok="addMenuCategory"
              class="btn custom-btn-style"
              v-b-modal.modal-standard
              variant=":class"
              >Add Menu Category
            </b-button>
            <b-modal
              id="modal-standard"
              :title="menu_category_title"
              title-class="font-18"
              ref="modal"
              @show="resetModal"
              @hidden="resetModal"
              @ok="handleOk"
            >
              <form ref="form" @submit.stop.prevent="handleSubmit">
                <div class="form-group">
                  <label>Menu Group</label>
                  <div>
                    <select
                      v-model="menu_group_id"
                      required
                      name="menu_group_id"
                      class="form-control-role input-style"
                    >
                      <option value="">Select Menu Group</option>
                      <option
                        v-for="menu in menuGroup"
                        :value="menu.id"
                        v-bind:key="menu.id"
                        :title="menu.group_name"
                      >
                        {{ menu.group_name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div>
                  <b-form-group
                    :state="menuCategoryNameState"
                    label="Menu Category Name"
                    label-for="name-input"
                    invalid-feedback="Name is required"
                  >
                    <b-form-input
                      id="name-input"
                      v-model="menu_category_name"
                      :state="menuCategoryNameState"
                      class="input-style"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="form-group">
                  <label>Serving</label>
                  <div>
                    <select
                      v-model="serving_id"
                      required
                      name="serving_id"
                      class="form-control-role input-style"
                    >
                      <option value="">Select Serving</option>
                      <option
                        v-for="serving in servings"
                        :value="serving.id"
                        v-bind:key="serving.id"
                        :title="serving.serving"
                      >
                        {{ serving.serving }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-group">
                  <label>Course</label>
                  <div>
                    <select
                      v-model="course_id"
                      required
                      name="course_id"
                      class="form-control-role input-style"
                    >
                      <option value="">Select Course</option>
                      <option
                        v-for="course in courses"
                        :value="course.id"
                        v-bind:key="course.id"
                        :title="course.course_type"
                      >
                        {{ course.course_type }}
                      </option>
                    </select>
                  </div>
                </div>
                <div>
                  <b-form-group
                    :state="menuCategoryDescState"
                    label="Menu Category Desc"
                    label-for="description-input"
                  >
                    <b-form-textarea
                      id="description-input"
                      v-model="menu_category_desc"
                      :state="menuCategoryDescState"
                    ></b-form-textarea>
                  </b-form-group>
                </div>
              </form>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
    <div class="row align-items-center mb-3">
      <div class="col-sm-12 col-md-3">
        <div class="dataTables_length">
          <label class="d-inline-flex align-items-center mb-0">
            Show
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            ></b-form-select>
            entries
          </label>
        </div>
      </div>
      <!-- Search -->
      <div class="col-sm-12 col-md-9">
        <div class="dataTables_filter text-md-right filter-dataTables">
          <label class="d-inline-flex align-items-center mb-0">
            Search:
            <b-form-input
              v-model="filter"
              type="search"
              placeholder="Search..."
              class="form-control form-control-sm ml-2"
            ></b-form-input>
          </label>
          <div class="ml-2">
            <button class="btn print excel-btn" @click="excelExport()">
              Export to Excel
            </button>
            <button class="btn print" @click="csvExport(csvData)">
              Export to CSV
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End search -->
    <div class="card">
      <div class="card-body p-0">
        <div class="row mb-md-2"></div>
        <!-- Table -->
        <div class="table-responsive mb-0">
          <b-table
            :items="menu_category"
            :fields="menu_category_fields"
            responsive="true"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template v-slot:cell(action)="menu_category">
              <div class="position-relative">
                <div class="table-actions">
                  <a
                    href="javascript:void(0)"
                    class="success-icon"
                    @click="editModal(menu_category)"
                    v-b-modal.modal-standard
                  >
                    <i class="fas fa-pencil-alt"></i>
                  </a>
                  <a
                    href="javascript:void(0)"
                    class="reject-icon"
                    @click="deleteMenuCategory(menu_category)"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </a>
                </div>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import XLSX from "xlsx";
import axios from "axios";
import helpers from "../../helpers";

export default {
  name: "RestaurantSettings",
  data() {
    return {
      loader: false,
      search: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: true,
      DropDownMenu: false,
      uuid: "",
      menu_category: [],
      restaurantId: this.$route.params.restaurant,
      restaurantUuid: this.$route.params.restaurant_uuid,
      menu_category_fields: [
        { key: "menu_category_name", label: "Menu", sortable: true },
        { key: "menu_group_name", label: "Group", sortable: true },
        { key: "course_type_name", label: "Course", sortable: true },
        { key: "serving_name", label: "Serve", sortable: true },
        { key: "action" }
      ],
      menuCategoryNameState: null,
      menu_category_name: "",
      menuCategoryDescState: null,
      fileState: null,
      menu_category_desc: "",
      courses: [],
      servings: [],
      menuGroup: [],
      course_id: "",
      serving_id: "",
      menu_group_id: "",
      is_edit: false,
      menu_category_title: "",
      menu_category_id: ""
    };
  },
  components: {},
  computed: {
    rows() {
      return this.menu_category.length;
    },
    csvData() {
      return this.menu_category.map(item => ({
        ...item
      }));
    }
  },

  methods: {
    imageLoaderError(event) {
      return (event.target.src = "icontest.png");
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.menuCategoryNameState = valid;
      this.menuCategoryDescState = valid;
      this.fileState = valid;
      return valid;
    },
    addMenuCategory() {
      this.menu_category_name = "";
      this.menu_category_desc = "";
      this.course_id = "";
      this.serving_id = "";
      this.menu_group_id = "";
      this.menuCategoryNameState = null;
      this.menuCategoryDescState = null;
      this.fileState = null;
      this.menu_category_title = "Add Menu Category";
    },
    resetModal() {},
    editModal(menu_category) {
      this.menuCategoryNameState = null;
      this.menuCategoryDescState = null;
      this.fileState = null;
      this.menu_category_id = menu_category.item.id;
      this.menu_group_id = menu_category.item.menu_group_id;
      this.menu_category_name = menu_category.item.menu_category_name;
      this.menu_category_desc = menu_category.item.description;
      this.serving_id = menu_category.item.serving_id;
      this.course_id = menu_category.item.course_type_id;
      this.is_edit = true;
      this.menu_category_title = "Edit Menu Category";
    },
    handleOk(bvModalEvt) {
      this.loader = true;
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();

      let URL = "";
      let toastMessage = "";

      let formData = new FormData();
      if (this.is_edit) {
        URL = this.$path + "/admin/update/menu/category";
        toastMessage = "Menu Category Updated!";
        formData.append("menu_category_id", this.menu_category_id);
      } else {
        URL = this.$path + "/admin/add/menu/category/" + this.restaurantUuid;
        toastMessage = "Menu Category Added!";
      }

      formData.append("menu_group_id", this.menu_group_id);
      formData.append("menu_category_name", this.menu_category_name);
      formData.append("menu_category_desc", this.menu_category_desc);
      formData.append("serving_id", this.serving_id);
      formData.append("course_id", this.course_id);

      ApiService.post(URL, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(() => {
          this.$bvToast.toast(toastMessage, {
            title: "Success",
            variant: "success",
            solid: true
          });
          this.getRestaurantMenuCategory();
          this.loader = false;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });

      // Prevent modal from closing
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-standard");
      });
    },
    getRestaurantMenu() {
      axios
        .get(this.$path + "/admin/restaurant/menu/" + this.restaurantUuid)
        .then(({ data }) => {
          this.menuGroup = data.data;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    getServings() {
      axios
        .get(this.$path + "/admin/get/servings")
        .then(({ data }) => {
          this.servings = data.data;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    getCourses() {
      axios
        .get(this.$path + "/admin/get/courses")
        .then(({ data }) => {
          this.courses = data.data;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    getRestaurantMenuCategory() {
      this.loader = true;
      axios
        .get(
          this.$path + "/admin/restaurant/menu/category/" + this.restaurantUuid
        )
        .then(({ data }) => {
          this.menu_category = data.data;
          this.loader = false;
        })
        .catch(err => {
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    deleteMenuCategory(menu_category) {
      this.loader = true;
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.isConfirmed) {
          axios
            .get(
              this.$path +
                "/admin/delete/menu/category/" +
                menu_category.item.uuid
            )
            .then(() => {
              this.$bvToast.toast("Menu Category Delete Successfully!", {
                title: "Success",
                variant: "success",
                solid: true
              });
              this.getRestaurantMenuCategory();
              this.loader = false;
            })
            .catch(err => {
              if (err.response.data.status === 400) {
                helpers.errorOther(this, err.response.data);
              }
              if (err.response.data.status === 422) {
                helpers.validationError(this, err.response.data.errors);
              }
              if (err.response.status === 404) {
                helpers.errorMessage(this, "url not found");
              }
              if (err.response.status === 500) {
                helpers.serverError(this);
              }
            });
        }
        this.loader = false;
      });
    },
    clickDots(uuid) {
      this.uuid = uuid;
      if (this.DropDownMenu) {
        this.DropDownMenu = false;
      } else {
        this.DropDownMenu = true;
      }
    },
    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join(";"),
        ...arrData.map(item => Object.values(item).join(";"))
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "MenuCategory.csv");
      link.click();
    },
    excelExport() {
      const data = XLSX.utils.json_to_sheet(this.menu_category);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "MenuCategory.xlsx");
    },
    closeMenu() {
      this.DropDownMenu = false;
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.closeMenu();
      }
    }
  },
  mounted() {
    document.addEventListener("click", this.close);
    this.restaurantData = JSON.parse(localStorage.getItem("restaurantDetails"));
    this.restaurantId = this.restaurantData.id;
    this.restaurantUuid = this.restaurantData.uuid;
    this.getRestaurantMenuCategory();
    this.getRestaurantMenu();
    this.getServings();
    this.getCourses();
    this.totalRows = this.menu_category.length;
  },
  beforeDestroy() {
    document.removeEventListener("click", this.close);
  }
};
</script>
