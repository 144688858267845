<template>
  <div>
    <b-modal
      hide-footer
      id="modal-standard-group"
      :title="menu_group_title"
      title-class="font-18"
      ref="modal"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          label="Group Name"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <b-form-input
            id="name-input"
            v-model="group_name"
            class="input-style"
            required
          ></b-form-input>
        </b-form-group>
        <div class="form-group">
          <label>Select Icon</label>
          <div class="menugroup-icon-selection">
            <label
              class=""
              v-for="icon in menu_icons"
              :value="icon.id"
              v-bind:key="icon.id"
            >
              <input
                @click="getImageName(icon.image_name)"
                type="radio"
                name="test"
                value="big"
              />
              <img :src="icon.image" width="50" />
            </label>
          </div>
        </div>
        <div class="form-group">
          <label>Assign Kitchen</label>
          <div>
            <select
              v-model="kitchen_uuid"
              required
              name="kitchen_uuid"
              class="form-control-role input-style"
            >
              <option value="">Select Kitchen</option>
              <option
                v-for="kitchen in kitchens"
                :value="kitchen.uuid"
                v-bind:key="kitchen.uuid"
                :title="kitchen.kitchen_name"
              >
                {{ kitchen.kitchen_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label>Assign Printer</label>
          <div>
            <select
              v-model="printer_uuid"
              required
              name="printer_uuid"
              class="form-control-role input-style"
            >
              <option value="">Select Printer</option>
              <option
                v-for="printer in printers"
                :value="printer.uuid"
                v-bind:key="printer.uuid"
                :title="printer.ip_address"
              >
                {{ printer.ip_address }}
              </option>
            </select>
          </div>
        </div>
        <div class="radio-btn-style">
          <b-form-group label="POS Display" class="pb-0">
            <div class="d-flex justify-content-start">
              <b-form-radio v-model="pos_dispay" name="some-radios" value="1"
                >Yes
              </b-form-radio>
              <b-form-radio
                v-model="pos_dispay"
                name="some-radios"
                value="0"
                class="ml-3"
                >No
              </b-form-radio>
            </div>
          </b-form-group>
        </div>
        <div class="text-right">
          <b-button
            type="button"
            class="mt-3 btn-orange"
            :disabled="isValidate"
            @click="handleOk"
            >Add</b-button
          >
        </div>
      </form>
    </b-modal>
  </div>
</template>

<style scoped></style>
<script>
import ApiService from "@/core/services/api.service";
import helpers from "../../../helpers";
import axios from "axios";

export default {
  name: "MenuGroupModal",
  data() {
    return {
      kitchens: [],
      menu_icons: [],
      printers: [],
      printer_uuid: "",
      pos_dispay: "",
      kitchen_uuid: "",
      group_name: "",
      menu_group_uuid: "",
      groupNameState: null,
      menu_group_id: "",
      is_edit: false,
      restaurantUuid: "",
      edit_menu: {},
      image_name: "",
      loader: false
    };
  },
  props: {
    menu_group_title: {
      type: String
    },
    obj_menu: {
      type: Object
    },
    is_edit_modal: {
      type: Boolean
    }
  },
  watch: {
    obj_menu() {
      this.updateMenuGroup();
    }
  },
  components: {},
  computed: {
    isValidate() {
      return !(
        this.kitchen_uuid !== "" &&
        this.group_name !== "" &&
        this.pos_dispay !== "" &&
        this.image_name !== ""
      );
    }
  },
  methods: {
    getImageName(image) {
      this.image_name = image;
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.groupNameState = valid;
      return valid;
    },
    resetModal() {},
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      // Trigger submit handler
      let obj = {
        group_name: this.group_name,
        is_pos_dispay: this.pos_dispay,
        src: this.image_name,
        kitchen_uuid: this.kitchen_uuid,
        printer_uuid: this.printer_uuid
      };

      this.loader = true;
      this.is_edit_modal
        ? this.updateMenuGroupApi(obj)
        : this.addMenuGroupApi(obj);

      // Prevent modal from closing
    },
    addMenuGroupApi(obj) {
      ApiService.post(
        this.$path + "/admin/menu/group/restaurant/" + this.restaurantUuid,
        obj
      )
        .then(response => {
          this.$emit("add_menu", response.data.data);
          helpers.successMessages(this, response.data.message);
          this.loader = false;
          this.handleSubmit();
        })
        .catch(err => {
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    updateMenuGroupApi(obj) {
      ApiService.patch(
        this.$path +
          "/admin/menu/group/restaurant/" +
          this.restaurantUuid +
          "/" +
          this.menu_group_uuid,
        obj
      )
        .then(response => {
          this.$emit("update_menu", response.data.data);
          helpers.successMessages(this, response.data.message);
          this.loader = false;
          this.handleSubmit();
        })
        .catch(err => {
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-standard-group");
      });
    },
    getRestaurantKitchens() {
      axios
        .get(this.$path + "/admin/kitchen/restaurant/" + this.restaurantUuid)
        .then(response => {
          this.kitchens = response.data.data;
        })
        .catch(err => {
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    getRestaurantPrinters() {
      axios
        .get(this.$path + "/admin/printer/restaurant/" + this.restaurantUuid)
        .then(response => {
          this.printers = response.data.data;
        })
        .catch(err => {
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    getMenuIcons() {
      axios
        .get(this.$path + "/admin/menu/group/icons")
        .then(response => {
          this.menu_icons = response.data.data;
        })
        .catch(err => {
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    updateMenuGroup() {
      if (this.obj_menu) {
        this.menu_group_uuid = this.obj_menu.uuid;
        this.group_name = this.obj_menu.group_name;
        if (this.obj_menu.kitchen) {
          this.kitchen_uuid = this.obj_menu.kitchen.uuid;
        }
        this.pos_dispay = this.obj_menu.is_pos_dispay;
        if (this.obj_menu.printer) {
          this.printer_uuid = this.obj_menu.printer.uuid;
        }
      }
    }
  },
  mounted() {
    this.updateMenuGroup();
    this.restaurantData = JSON.parse(localStorage.getItem("restaurantDetails"));
    this.restaurantUuid = this.restaurantData.uuid;
    this.getRestaurantKitchens();
    this.getRestaurantPrinters();
    this.getMenuIcons();
  }
};
</script>

<style scoped></style>
