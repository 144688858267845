<template>
  <div>
    <div class="card card-custom no-box-shadow">
      <div class="card-body resturant-setting-vue p-0">
        <div
          class="d-flex flex-wrap justify-content-start restaurant-setting-menu"
        >
          <div v-for="item in menu" :key="item.id">
            <button
              :class="item.class"
              @click="updateClass(item.id, item.path)"
            >
              {{ item.title }}
            </button>
          </div>
          <div class="w-100 mt-4">
            <LocalOffers v-if="type_offer === '/local_offers'"></LocalOffers>
            <PromoCodes v-if="type_offer === '/promo_codes'"></PromoCodes>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>

<script>
import "vue2-timepicker/dist/VueTimepicker.css";
import LocalOffers from "./LocalOffers";
import PromoCodes from "./PromoCodes";

export default {
  name: "Offers",
  data() {
    return {
      type_offer: "/local_offers",
      menu: [
        {
          id: 1,
          path: "/local_offers",
          title: "Local Offers",
          class: "btn btn--menu active"
        },
        {
          id: 2,
          path: "/promo_codes",
          title: "Promo Codes",
          class: "btn btn--menu"
        }
      ]
    };
  },
  components: {
    // VueTimepicker
    LocalOffers,
    PromoCodes
  },
  computed: {
    isFormValid() {
      return !(
        this.type !== "" &&
        this.offer_title !== "" &&
        this.charge_type !== "" &&
        this.effective_at !== "" &&
        this.expire_at !== "" &&
        this.offer_quantity !== "" &&
        this.is_reuseable !== ""
      );
    }
  },

  methods: {
    updateClass(id, path) {
      for (let i = 0; i < this.menu.length; i++) {
        this.menu[i].class = "btn btn--menu";
        if (this.menu[i].id === id) {
          this.menu[i].class = "btn btn--menu active";
          // console.log(path);
          this.type_offer = path;
          // router.push({path: path});
        }
      }
    }
  },
  mounted() {}
};
</script>
