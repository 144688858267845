<template>
  <div>
    <h4 class="card-label border-bottom mb-3">Local Offers</h4>
    <div class="loader-wrapper" v-if="loader">
      <div class="image-loader"></div>
    </div>
    <div class="form-group">
      <div class="card-body">
        <div class="row">
          <div class="text-center">
            <b-button
              v-if="permission.is_add == 1"
              @ok="addOffer"
              class="btn custom-btn-style"
              v-b-modal.modal-standard
              variant=":class"
              >Add Local Offer
            </b-button>
            <b-modal
              hide-footer
              id="modal-standard"
              title="Add Offer"
              title-class="font-18"
              ref="modal"
              @show="resetModal"
              @hidden="resetModal"
            >
              <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group
                  label="Title"
                  label-for="title-input"
                  invalid-feedback="Title is required"
                >
                  <b-form-input
                    id="title-input"
                    v-model="title"
                    class="input-style"
                    required
                  ></b-form-input>
                </b-form-group>
                <div class="form-group">
                  <label>Discount</label>
                  <div class="d-flex">
                    <select
                      v-model="charge_type"
                      required
                      class="form-control-role input-style"
                    >
                      <option value="">Discount type:</option>
                      <option
                        v-for="type in charge_types"
                        :value="type.title"
                        v-bind:key="type.title"
                        :title="type.title"
                      >
                        {{ type.title }}
                      </option>
                    </select>
                    <input
                      v-model="charge"
                      type="number"
                      min="1"
                      placeholder="value"
                      class="form-control input-style ml-2"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label>Reward Type</label>
                  <div class="d-flex">
                    <select
                      v-model="reward_type"
                      required
                      class="form-control-role input-style"
                    >
                      <option value="">Reward Type:</option>
                      <option
                        v-for="type in reward_types"
                        :value="type.text"
                        v-bind:key="type.text"
                        :title="type.text"
                      >
                        {{ type.text }}
                      </option>
                    </select>
                  </div>
                </div>
                <b-form-group
                  label="Quantity"
                  label-for="quantity-input"
                  invalid-feedback="Quantity is required"
                >
                  <b-form-input
                    type="number"
                    min="1"
                    id="quantity-input"
                    v-model="quantity"
                    class="input-style"
                    required
                  ></b-form-input>
                </b-form-group>
                <div
                  class="form-group d-flex justify-content-between time-picker-wrapper"
                >
                  <div>
                    <label>Effective At</label><br />
                    <VueCtkDateTimePicker v-model="effective_at" />
                  </div>
                  <div>
                    <label>Expire At</label><br />
                    <VueCtkDateTimePicker v-model="expire_at" />
                  </div>
                </div>
                <div class="radio-btn-style">
                  <b-form-group label="Is Reuseable">
                    <div class="d-flex justify-content-start">
                      <b-form-radio
                        v-model="is_reusable"
                        name="some-radios"
                        value="1"
                        >Yes
                      </b-form-radio>
                      <b-form-radio
                        v-model="is_reusable"
                        name="some-radios"
                        value="0"
                        class="ml-3"
                        >No
                      </b-form-radio>
                    </div>
                  </b-form-group>
                </div>
                <b-form-group label="Description" label-for="desc-input">
                  <b-form-input
                    id="desc-input"
                    v-model="description"
                    class="input-style"
                  ></b-form-input>
                </b-form-group>
                <div class="m-t-15">
                  <b-button
                    type="button"
                    @click="handleOk"
                    class="mt-3 btn-orange pull-right"
                    :disabled="isFormValid"
                  >
                    Add
                  </b-button>
                </div>
              </form>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
    <div class="row align-items-center mb-3">
      <div class="col-sm-12 col-md-3">
        <div id="tickets-table_length" class="dataTables_length">
          <label class="d-inline-flex align-items-center mb-0">
            Show
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            ></b-form-select>
            entries
          </label>
        </div>
      </div>
      <!-- Search -->
      <div class="col-sm-12 col-md-9">
        <div
          id="tickets-table_filter"
          class="dataTables_filter filter-dataTables text-md-right"
        >
          <label class="d-inline-flex align-items-center mb-0">
            Search:
            <b-form-input
              v-model="filter"
              type="search"
              placeholder="Search..."
              class="form-control form-control-sm ml-2"
            ></b-form-input>
          </label>
          <div class="ml-2">
            <button class="btn print excel-btn" @click="excelExport()">
              Export to Excel
            </button>
            <button class="btn print" @click="csvExport(csvData)">
              Export to CSV
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End search -->
    <div class="card">
      <div class="card-body p-0">
        <div class="row mb-md-2"></div>
        <!-- Table -->
        <div class="table-responsive mb-0">
          <b-table
            :items="offers"
            :fields="fields"
            responsive="true"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template v-slot:cell(charge_type)="offers">
              <div>
                {{ capitalizeFirstLetter(offers.item.charge_type) }}
              </div>
            </template>
            <template v-slot:cell(reward_type)="offers">
              <div>
                {{ capitalizeFirstLetter(offers.item.reward_type) }}
              </div>
            </template>
            <template v-slot:cell(action)="offers">
              <div class="position-relative">
                <div class="table-actions">
                  <a href="javascript:void(0)">
                    <i class="fas fa-pencil-alt"></i>
                  </a>
                  <a
                    v-if="permission.is_deleted == 1"
                    href="javascript:void(0)"
                    class="reject-icon"
                    @click="deleteOffer(offers)"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </a>
                </div>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
// import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import XLSX from "xlsx";
import helpers from "../../helpers";
import axios from "axios";

export default {
  name: "Offers",
  data() {
    return {
      permission: {
        is_add: 1,
        is_deleted: 1,
        is_edit: 1,
        web_access_enabled: 1
      },
      types: [{ id: 1, type: "App Local Offers" }],
      LOCAL_OFFERS: 1,
      PROMOCODES: 3,
      loader: false,
      search: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: true,
      offers: [],
      restaurantId: "",
      restaurantUuid: "",
      DropDownMenu: false,
      uuid: "",
      dropDownListCheck: false,
      fields: [
        { key: "title", label: "Title", sortable: true },
        { key: "reward_code", label: "Code", sortable: true },
        { key: "quantity", label: "Quantity", sortable: true },
        { key: "charge_type", label: "Charge Type", sortable: true },
        { key: "reward_type", label: "Reward Type", sortable: true },
        { key: "discount", label: "Discount", sortable: true },
        { key: "eff_at", label: "Start Date", sortable: true },
        { key: "exp_at", label: "Expire Date", sortable: true },
        { key: "action" }
      ],
      type: "",
      title: "",
      effective_at: "",
      expire_at: "",
      quantity: "",
      is_reusable: "",
      description: "",
      charge_type: "",
      charge_types: [{ title: "Percentage" }, { title: "Amount" }],
      charge: "",
      reward_type: "",
      reward_types: [
        { text: "Anytime" },
        { text: "Day" },
        { text: "Night" },
        { text: "Weekdays" },
        { text: "Weekends" }
      ]
    };
  },
  components: {
    // VueTimepicker
  },
  computed: {
    rows() {
      return this.offers.length;
    },
    csvData() {
      return this.offers.map(item => ({
        ...item
      }));
    },
    isFormValid() {
      return !(
        this.reward_type !== "" &&
        this.title !== "" &&
        this.charge_type !== "" &&
        this.effective_at !== "" &&
        this.expire_at !== "" &&
        this.quantity !== "" &&
        this.is_reusable !== ""
      );
    }
  },

  methods: {
    addOffer() {},
    resetModal() {
      this.title = "";
      this.effective_at = "";
      this.expire_at = "";
      this.quantity = "";
      this.is_reusable = "";
      this.description = "";
      this.reward_type = "";
      this.charge = "";
      this.charge_type = "";
    },
    clickDots(uuid) {
      this.uuid = uuid;
      if (this.DropDownMenu) {
        this.DropDownMenu = false;
      } else {
        this.DropDownMenu = true;
      }
    },
    handleOk() {
      this.loader = true;
      let obj = {
        type: this.type,
        title: this.title,
        quantity: this.quantity,
        eff_at: this.effective_at,
        exp_at: this.expire_at,
        is_reusable: parseInt(this.is_reusable) ? true : false,
        description: this.description,
        discount: this.charge,
        charge_type: this.charge_type.toLowerCase(),
        reward_type: this.reward_type.toLowerCase()
      };
      if (this.charge_type === "Percentage") {
        if (parseFloat(this.charge) >= 100) {
          helpers.errorMessage(
            this,
            "The discount should be less then 100 when you select Percentage"
          );
          return;
        }
      }
      ApiService.post(
        this.$path + "/admin/rewards/restaurant/" + this.restaurantUuid,
        obj
      )
        .then(response => {
          this.offers.unshift(response.data.data);
          helpers.successMessages(this, "Offer added successfully!");
          this.loader = false;
          this.$bvModal.hide("modal-standard");
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    getRestaurantOffers() {
      this.loader = true;
      axios
        .get(this.$path + "/admin/rewards/restaurant/" + this.restaurantUuid)
        .then(response => {
          this.offers = response.data.data;
          this.loader = false;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    deleteOffer(offer) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.isConfirmed) {
          this.loader = true;
          ApiService.delete(
            this.$path +
              "/admin/rewards/restaurant/" +
              this.restaurantUuid +
              "/" +
              offer.item.uuid
          )
            .then(response => {
              helpers.successMessages(this, "Offer delete successfully");
              let index = this.offers.findIndex(
                x => x.uuid === response.data.data.uuid
              );
              if (index !== -1) {
                this.offers.splice(index, 1);
              }
              this.loader = false;
            })
            .catch(err => {
              this.loader = false;
              if (err.response.data.status === 400) {
                helpers.errorOther(this, err.response.data);
              }
              if (err.response.data.status === 422) {
                helpers.validationError(this, err.response.data.errors);
              }
              if (err.response.status === 404) {
                helpers.errorMessage(this, "url not found");
              }
              if (err.response.status === 500) {
                helpers.serverError(this);
              }
            });
        }
      });
    },
    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join(";"),
        ...arrData.map(item => Object.values(item).join(";"))
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "Offers.csv");
      link.click();
    },
    excelExport() {
      const data = XLSX.utils.json_to_sheet(this.offers);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "Offers.xlsx");
    },
    closeMenu() {
      this.DropDownMenu = false;
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.closeMenu();
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  },
  watch: {
    effective_at() {
      var timeStr = this.effective_at;
      if (
        timeStr &&
        timeStr.indexOf(" ") !== -1 &&
        timeStr.indexOf(":") !== -1
      ) {
        var hrs = 0;
        var tempAry = timeStr.split(" ");
        var date = tempAry[0];
        var hrsMinAry = tempAry[1].split(":");
        hrs = parseInt(hrsMinAry[0], 10);
        if ((tempAry[2] == "AM" || tempAry[2] == "am") && hrs == 12) {
          hrs = 0;
        } else if ((tempAry[2] == "PM" || tempAry[2] == "pm") && hrs != 12) {
          hrs += 12;
        }
        var timeFormat =
          date +
          " " +
          ("0" + hrs).slice(-2) +
          ":" +
          ("0" + parseInt(hrsMinAry[1], 10)).slice(-2) +
          ":00";
        this.effective_at = timeFormat;
        // console.log('Selected Time date : ', timeFormat);
        // return ('0' + hrs).slice(-2) + ':' + ('0' + parseInt(hrsMinAry[1], 10)).slice(-2);
      }
    },
    expire_at() {
      var timeStr = this.expire_at;
      if (
        timeStr &&
        timeStr.indexOf(" ") !== -1 &&
        timeStr.indexOf(":") !== -1
      ) {
        var hrs = 0;
        var tempAry = timeStr.split(" ");
        var date = tempAry[0];
        var hrsMinAry = tempAry[1].split(":");
        hrs = parseInt(hrsMinAry[0], 10);
        if ((tempAry[2] == "AM" || tempAry[2] == "am") && hrs == 12) {
          hrs = 0;
        } else if ((tempAry[2] == "PM" || tempAry[2] == "pm") && hrs != 12) {
          hrs += 12;
        }
        var timeFormat =
          date +
          " " +
          ("0" + hrs).slice(-2) +
          ":" +
          ("0" + parseInt(hrsMinAry[1], 10)).slice(-2) +
          ":00";
        this.expire_at = timeFormat;
      }
    }
  },
  mounted() {
    document.addEventListener("click", this.close);
    this.restaurantData = JSON.parse(localStorage.getItem("restaurantDetails"));
    this.restaurantId = this.restaurantData.id;
    this.restaurantUuid = this.restaurantData.uuid;
    this.getRestaurantOffers();
    // this.getChargeTypes();
    this.totalRows = this.offers.length;
    let permission = JSON.parse(localStorage.getItem("currentUser")).permission;
    this.permission = {
      is_add: permission.is_add,
      is_deleted: permission.is_deleted,
      is_edit: permission.is_edit,
      web_access_enabled: permission.web_access_enabled
    };
  },
  beforeDestroy() {
    document.removeEventListener("click", this.close);
  }
};
</script>
