<template>
  <div>
    <div>
      <h4 class="card-label border-bottom mb-3">MENU</h4>
    </div>
    <div class="treeview-wrapper">
      <div class="treeview-inner">
        <div class="treeview-header text-center">
          <h4 class="text-center mb-0">Menu Group</h4>
        </div>
        <hr />
        <div class="treeview-body-content">
          <div
            v-for="menu in menu_group"
            :value="menu.id"
            v-bind:key="menu.id"
            :title="menu.group_name"
            @click="getCategories(menu)"
          >
            <div @click="show_categories(menu)">
              <div
                :class="
                  checkObject(menu)
                    ? 'treeView-inner-content isactive'
                    : 'treeView-inner-content'
                "
              >
                <div class="treeview-heading-area">
                  <img :src="menu.src" alt="img" @error="imageLoaderError" />
                  <h5 class="mb-0">{{ menu.group_name }}</h5>
                </div>
                <div class="table-actions text-right">
                  <a
                    v-if="permission.is_edit == 1"
                    href="javascript:void(0)"
                    class="success-icon"
                    @click="editMenuGroupModal(menu)"
                    v-b-modal.modal-standard-group
                    ><i class="fas fa-pencil-alt"></i
                  ></a>
                  <a
                    v-if="permission.is_deleted == 1"
                    href="javascript:void(0)"
                    class="reject-icon"
                    @click="deleteMenuGroup(menu)"
                    ><i class="fas fa-trash-alt"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="treeview-footer mt-2 text-center">
          <button
            type="button"
            v-if="permission.is_add"
            @click="addMenuGroup"
            class="btn custom-btn-style"
            v-b-modal.modal-standard-group
          >
            Add
          </button>
          <MenuGroupModal
            :menu_group_title="menu_group_title"
            v-if="is_group_modal"
            @add_menu="add_menu"
            @update_menu="update_menu"
            :obj_menu="obj_menu"
            :is_edit_modal="is_edit_modal"
          />
        </div>
      </div>
      <div v-show="show_category" class="treeview-inner">
        <div class="treeview-header text-center">
          <h4 class="text-center mb-0">Menu Category</h4>
        </div>
        <hr />
        <div class="treeview-body-content">
          <div
            v-show="is_category"
            v-for="menu in menu_category"
            :value="menu.uuid"
            v-bind:key="menu.uuid"
            :title="menu.menu_category_name"
            @click="getItems(menu)"
          >
            <div @click="show_items(menu)">
              <div
                :class="
                  checkCategoryObject(menu)
                    ? 'treeView-inner-content isactive'
                    : 'treeView-inner-content'
                "
              >
                <div class="treeview-heading-area">
                  <h5 class="mb-0">{{ menu.menu_category_name }}</h5>
                </div>
                <div class="table-actions text-right">
                  <a
                    v-if="permission.is_edit == 1"
                    href="javascript:void(0)"
                    class="success-icon"
                    @click="editMenuCategoryModal(menu)"
                    v-b-modal.modal-standard-category
                    ><i class="fas fa-pencil-alt"></i
                  ></a>
                  <a
                    v-if="permission.is_deleted == 1"
                    href="javascript:void(0)"
                    class="reject-icon"
                    @click="deleteMenuCategory(menu)"
                    ><i class="fas fa-trash-alt"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="treeview-footer mt-2 text-center">
          <button
            type="button"
            v-if="permission.is_add == 1"
            @click="addMenuCategory"
            class="btn custom-btn-style"
            v-b-modal.modal-standard-category
          >
            Add
          </button>
          <MenuCategoryModal
            :menu_group_uuid="menu_group_uuid"
            :obj_menu_category="obj_menu_category"
            @add_category="add_category"
            @update_category="update_category"
            :menu_category_title="menu_category_title"
            :is_edit_category_modal="is_edit_category_modal"
            v-if="is_category_modal"
          />
        </div>
      </div>
      <div v-show="show_item" class="treeview-inner">
        <div class="treeview-header text-center">
          <h4 class="text-center mb-0">Items</h4>
        </div>
        <hr />
        <div class="treeview-body-content">
          <div
            v-show="is_item"
            v-for="menu in menu_items"
            :value="menu.uuid"
            v-bind:key="menu.uuid"
            :title="menu.item_name"
            @click="getItemExtras(menu)"
          >
            <div @click="show_extras(menu)">
              <div
                :class="
                  checkItemsObject(menu)
                    ? 'treeView-inner-content isactive'
                    : 'treeView-inner-content'
                "
              >
                <div class="treeview-heading-area">
                  <img :src="menu.src" alt="img" @error="imageLoaderError" />
                  <h5 class="mb-0">{{ menu.item_name }}</h5>
                </div>
                <div class="table-actions text-right">
                  <a
                    v-if="permission.is_edit == 1"
                    href="javascript:void(0)"
                    class="success-icon"
                    @click="editMenuItemModal(menu)"
                    v-b-modal.modal-standard-item
                    ><i class="fas fa-pencil-alt"></i
                  ></a>
                  <a
                    v-if="permission.is_deleted == 1"
                    href="javascript:void(0)"
                    class="reject-icon"
                    @click="deleteMenuItem(menu)"
                    ><i class="fas fa-trash-alt"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="treeview-footer mt-2 text-center">
          <button
            type="button"
            v-if="permission.is_add == 1"
            @click="addMenuItem"
            class="btn custom-btn-style"
            v-b-modal.modal-standard-item
          >
            Add
          </button>
          <MenuItemModal
            :menu_category_uuid="menu_category_uuid"
            :obj_menu_item="obj_menu_item"
            @add_item="add_item"
            @update_item="update_item"
            :menu_item_title="menu_item_title"
            :is_edit_item_modal="is_edit_item_modal"
            v-if="is_item_modal"
          />
        </div>
      </div>
      <div v-show="show_item_extra" class="treeview-inner">
        <div class="treeview-header">
          <h4 class="text-center">Item Extras</h4>
        </div>
        <hr />
        <div class="treeview-body-content">
          <div
            v-show="is_item_extra"
            v-for="menu in menu_item_extra"
            :value="menu.id"
            v-bind:key="menu.id"
            :title="menu.extra_name"
          >
            <div class="treeView-inner-content">
              <div class="treeview-heading-area">
                <h5 class="mb-0">{{ menu.extra_name }}</h5>
              </div>
              <div class="table-actions text-right">
                <a
                  v-if="permission.is_edit == 1"
                  href="javascript:void(0)"
                  class="success-icon"
                  @click="editMenuItemExtraModal(menu)"
                  v-b-modal.modal-standard-extra
                  ><i class="fas fa-pencil-alt"></i
                ></a>
                <a
                  v-if="permission.is_deleted == 1"
                  href="javascript:void(0)"
                  class="reject-icon"
                  @click="deleteMenuItemExtra(menu)"
                  ><i class="fas fa-trash-alt"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
        <div class="treeview-footer mt-2 text-center">
          <button
            type="button"
            v-if="permission.is_add == 1"
            @click="addMenuItemExtra"
            class="btn custom-btn-style"
            v-b-modal.modal-standard-extra
          >
            Add
          </button>
          <MenuItemExtraModal
            :menu_item_uuid="menu_item_uuid"
            :obj_menu_item_extra="obj_menu_item_extra"
            @add_item_extra="add_item_extra"
            @update_item_extra="update_item_extra"
            :menu_item_extra_title="menu_item_extra_title"
            :is_edit_item_extra_modal="is_edit_item_extra_modal"
            v-if="is_item_extra_modal"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
<script>
import ApiService from "@/core/services/api.service";
// import XLSX from "xlsx";
import Swal from "sweetalert2";
import MenuGroupModal from "./MenuGroupModal";
import MenuCategoryModal from "./MenuCategoryModal";
import MenuItemModal from "./MenuItemModal";
import MenuItemExtraModal from "./MenuItemExtraModal";
import helpers from "../../../helpers";
import axios from "axios";

export default {
  name: "RestaurantSettings",
  data() {
    return {
      permission: {
        is_add: 1,
        is_deleted: 1,
        is_edit: 1,
        web_access_enabled: 1
      },
      isactive: false,
      selectedMenuActive: [
        {
          id: -1
        }
      ],
      selectedCategoryActive: [
        {
          id: -1
        }
      ],
      selectedItemsActive: [
        {
          id: -1
        }
      ],
      obj_menu: {},
      obj_menu_category: {},
      obj_menu_item: {},
      obj_menu_item_extra: {},
      menu_group: [],
      menu_category: [],
      menu_items: [],
      menu_item_extra: [],
      restaurantUuid: JSON.parse(localStorage.getItem("restaurantDetails"))
        .uuid,
      Uuid: "",
      group_type: 1,
      category_type: 0,
      item_type: 0,
      extra_item_type: 0,
      is_category: false,
      is_item: false,
      is_item_extra: false,
      is_group_modal: false,
      is_category_modal: false,
      is_item_modal: false,
      is_item_extra_modal: false,
      menu_group_title: "",
      menu_category_title: "",
      menu_item_title: "",
      menu_item_extra_title: "",
      is_edit_modal: false,
      is_edit_category_modal: false,
      is_edit_item_modal: false,
      is_edit_item_extra_modal: false,
      show_category: false,
      show_item: false,
      show_item_extra: false,
      menu_group_uuid: "",
      menu_category_uuid: "",
      menu_item_uuid: ""
    };
  },
  components: {
    MenuGroupModal,
    MenuCategoryModal,
    MenuItemModal,
    MenuItemExtraModal
  },
  computed: {},
  methods: {
    imageLoaderError(event) {
      return (event.target.src = "icontest.png");
    },
    show_categories(menu) {
      this.menu_group_uuid = menu.uuid;
      this.show_category = true;
      this.show_item = false;
      this.show_item_extra = false;
    },
    show_items(menu) {
      this.menu_category_uuid = menu.uuid;
      this.show_item = true;
      this.show_item_extra = false;
    },
    show_extras(menu) {
      this.menu_item_uuid = menu.uuid;
      this.show_item_extra = true;
    },
    add_menu(data) {
      this.menu_group.unshift(data);
      // this.menu_group.reverse();
    },
    update_menu(data) {
      let index = this.menu_group.findIndex(x => x.uuid === data.uuid);
      if (index !== -1) {
        this.menu_group[index].group_name = data.group_name;
        this.menu_group[index].src = data.src;
      }
    },
    add_category(data) {
      this.menu_category.unshift(data);
    },
    update_category(data) {
      let index = this.menu_category.findIndex(x => x.uuid === data.uuid);
      if (index !== -1) {
        this.menu_category[index].menu_category_name = data.menu_category_name;
        this.menu_category[index].serving = data.serving;
        this.menu_category[index].course_type = data.course_type;
        this.menu_category[index].description = data.description;
      }
    },
    add_item(data) {
      this.menu_items.push(data);
      // this.menu_items.reverse();
    },
    update_item(data) {
      let index = this.menu_items.findIndex(x => x.uuid === data.uuid);
      if (index !== -1) {
        this.menu_items[index].item_name = data.item_name;
        this.menu_items[index].src = data.src;
      }
    },
    add_item_extra(data) {
      this.menu_item_extra.push(data);
    },
    update_item_extra(data) {
      let index = this.menu_item_extra.findIndex(x => x.uuid === data.uuid);
      if (index !== -1) {
        this.menu_item_extra[index].extra_name = data.extra_name;
      }
    },
    checkObject(menu) {
      return this.selectedMenuActive[0].id === menu.uuid;
    },
    checkCategoryObject(menu) {
      return this.selectedCategoryActive[0].id === menu.uuid;
    },
    checkItemsObject(menu) {
      return this.selectedItemsActive[0].id === menu.uuid;
    },
    getRestaurantMenu() {
      if (this.group_type === 1) {
        this.loader = true;
        axios
          .get(
            this.$path + "/admin/menu/group/restaurant/" + this.restaurantUuid
          )
          .then(response => {
            this.loader = false;
            this.menu_group = response.data.data;
          })
          .catch(err => {
            this.loader = false;
            if (err.response.data.status === 400) {
              helpers.errorOther(this, err.response.data);
            }
            if (err.response.data.status === 422) {
              helpers.validationError(this, err.response.data.errors);
            }
            if (err.response.status === 404) {
              helpers.errorMessage(this, "url not found");
            }
            if (err.response.status === 500) {
              helpers.serverError(this);
            }
          });
      }
      if (this.category_type === 2) {
        axios
          .get(this.$path + "/admin/menu/category/group/" + this.Uuid)
          .then(response => {
            this.menu_category = response.data.data;
          })
          .catch(err => {
            this.loader = false;
            if (err.response.data.status === 400) {
              helpers.errorOther(this, err.response.data);
            }
            if (err.response.data.status === 422) {
              helpers.validationError(this, err.response.data.errors);
            }
            if (err.response.status === 404) {
              helpers.errorMessage(this, "url not found");
            }
            if (err.response.status === 500) {
              helpers.serverError(this);
            }
          });
      }
      if (this.item_type === 3) {
        axios
          .get(this.$path + "/admin/menu/items/category/" + this.Uuid)
          .then(response => {
            this.menu_items = response.data.data;
          })
          .catch(err => {
            this.loader = false;
            if (err.response.data.status === 400) {
              helpers.errorOther(this, err.response.data);
            }
            if (err.response.data.status === 422) {
              helpers.validationError(this, err.response.data.errors);
            }
            if (err.response.status === 404) {
              helpers.errorMessage(this, "url not found");
            }
            if (err.response.status === 500) {
              helpers.serverError(this);
            }
          });
      }
      if (this.extra_item_type === 4) {
        axios
          .get(this.$path + "/admin/menu/extra/item/" + this.Uuid)
          .then(response => {
            this.menu_item_extra = response.data.data;
          })
          .catch(err => {
            this.loader = false;
            if (err.response.data.status === 400) {
              helpers.errorOther(this, err.response.data);
            }
            if (err.response.data.status === 422) {
              helpers.validationError(this, err.response.data.errors);
            }
            if (err.response.status === 404) {
              helpers.errorMessage(this, "url not found");
            }
            if (err.response.status === 500) {
              helpers.serverError(this);
            }
          });
      }
    },
    getCategories(menu) {
      this.selectedMenuActive[0].id = menu.uuid;
      this.Uuid = menu.uuid;
      this.is_category = true;
      this.is_item = false;
      this.is_item_extra = false;
      this.group_type = 0;
      this.item_type = 0;
      this.extra_item_type = 0;
      this.category_type = 2;
      this.getRestaurantMenu();
    },
    getItems(menu) {
      this.selectedCategoryActive[0].id = menu.uuid;
      this.Uuid = menu.uuid;
      this.is_item = true;
      this.is_item_extra = false;
      this.group_type = 0;
      this.category_type = 0;
      this.extra_item_type = 0;
      this.item_type = 3;
      this.getRestaurantMenu();
    },
    getItemExtras(menu) {
      this.selectedItemsActive[0].id = menu.uuid;
      this.Uuid = menu.uuid;
      this.is_item_extra = true;
      this.group_type = 0;
      this.category_type = 0;
      this.item_type = 0;
      this.extra_item_type = 4;
      this.getRestaurantMenu();
    },
    deleteMenuGroup(menu_group) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.isConfirmed) {
          this.loader = true;
          ApiService.delete(
            this.$path +
              "/admin/menu/group/restaurant/" +
              this.restaurantUuid +
              "/" +
              menu_group.uuid
          )
            .then(response => {
              this.show_category = false;
              this.show_item = false;
              this.show_item_extra = false;
              helpers.successMessages(this, response.data.message);
              let index = this.menu_group.findIndex(
                x => x.uuid === response.data.data.uuid
              );
              if (index !== -1) {
                this.menu_group.splice(index, 1);
                this.is_category = false;
                this.is_item = false;
                this.is_item_extra = false;
              }
            })
            .catch(err => {
              this.loader = false;
              if (err.response.data.status === 400) {
                helpers.errorOther(this, err.response.data);
              }
              if (err.response.data.status === 422) {
                helpers.validationError(this, err.response.data.errors);
              }
              if (err.response.status === 404) {
                helpers.errorMessage(this, "url not found");
              }
              if (err.response.status === 500) {
                helpers.serverError(this);
              }
            });
        }
      });
    },
    deleteMenuCategory(menu_category) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.isConfirmed) {
          axios
            .delete(
              this.$path +
                "/admin/menu/category/group/" +
                menu_category.menu_group.uuid +
                "/" +
                menu_category.uuid
            )
            .then(({ data }) => {
              if (data.data === 0) {
                this.$bvToast.toast(
                  "Cannot delete menu category. Some Items of this category belongs to orders!",
                  {
                    title: "Failed",
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center"
                  }
                );
              } else {
                this.show_item = false;
                this.show_item_extra = false;
                this.$bvToast.toast("Menu Category Delete Successfully!", {
                  title: "Success",
                  variant: "success",
                  solid: true,
                  toaster: "b-toaster-top-center"
                });
                let index = this.menu_category.findIndex(
                  x => x.uuid === menu_category.uuid
                );
                if (index !== -1) {
                  this.menu_category.splice(index, 1);
                  this.is_item = false;
                  this.is_item_extra = false;
                }
              }
            })
            .catch(err => {
              this.loader = false;
              if (err.response.data.status === 400) {
                helpers.errorOther(this, err.response.data);
              }
              if (err.response.data.status === 422) {
                helpers.validationError(this, err.response.data.errors);
              }
              if (err.response.status === 404) {
                helpers.errorMessage(this, "url not found");
              }
              if (err.response.status === 500) {
                helpers.serverError(this);
              }
            });
        }
      });
    },
    deleteMenuItem(menu_item) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.isConfirmed) {
          axios
            .delete(
              this.$path +
                "/admin/menu/items/category/" +
                menu_item.menu_category.uuid +
                "/" +
                menu_item.uuid
            )
            .then(({ data }) => {
              if (data.data === 0) {
                this.$bvToast.toast(
                  "Cannot delete Item. Item belongs to orders!",
                  {
                    title: "Failed",
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center"
                  }
                );
              } else {
                this.show_item_extra = false;
                this.$bvToast.toast("Menu Item Delete Successfully!", {
                  title: "Success",
                  variant: "success",
                  solid: true,
                  toaster: "b-toaster-top-center"
                });
                let index = this.menu_items.findIndex(
                  x => x.uuid === menu_item.uuid
                );
                if (index !== -1) {
                  this.menu_items.splice(index, 1);
                  this.is_item_extra = false;
                }
              }
            })
            .catch(err => {
              this.loader = false;
              this.show_item_extra = false;
              if (err.response.data.status === 400) {
                helpers.errorOther(this, err.response.data);
              }
              if (err.response.data.status === 422) {
                helpers.validationError(this, err.response.data.errors);
              }
              if (err.response.status === 404) {
                helpers.errorMessage(this, "url not found");
              }
              if (err.response.status === 500) {
                helpers.serverError(this);
              }
            });
        }
      });
    },
    deleteMenuItemExtra(item_extra) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.isConfirmed) {
          axios
            .delete(
              this.$path +
                "/admin/menu/extra/item/" +
                item_extra.menu_item.uuid +
                "/" +
                item_extra.uuid
            )
            .then(() => {
              this.$bvToast.toast("Menu Item Extra Deleted Successfully!", {
                title: "Success",
                variant: "success",
                solid: true,
                toaster: "b-toaster-top-center"
              });
              let index = this.menu_item_extra.findIndex(
                x => x.uuid === item_extra.uuid
              );
              if (index !== -1) {
                this.menu_item_extra.splice(index, 1);
              }
            })
            .catch(err => {
              this.loader = false;
              if (err.response.data.status === 400) {
                helpers.errorOther(this, err.response.data);
              }
              if (err.response.data.status === 422) {
                helpers.validationError(this, err.response.data.errors);
              }
              if (err.response.status === 404) {
                helpers.errorMessage(this, "url not found");
              }
              if (err.response.status === 500) {
                helpers.serverError(this);
              }
            });
        }
      });
    },
    addMenuGroup() {
      this.obj_menu = {};
      this.menu_group_title = "Add Menu Group";
      this.is_group_modal = true;
      this.is_edit_modal = false;
    },
    editMenuGroupModal(menu_group) {
      this.obj_menu = menu_group;
      this.is_group_modal = true;
      this.is_edit_modal = true;
      this.menu_group_title = "Edit Menu Group";
    },
    addMenuCategory() {
      this.obj_menu_category = {
        serving: {},
        course_type: {}
      };
      this.menu_category_title = "Add Menu Category";
      this.is_category_modal = true;
      this.is_edit_category_modal = false;
    },
    editMenuCategoryModal(category) {
      this.obj_menu_category = category;
      this.is_category_modal = true;
      this.is_edit_category_modal = true;
      this.menu_category_title = "Edit Menu Category";
    },
    addMenuItem() {
      this.obj_menu_item = {};
      this.menu_item_title = "Add Item";
      this.is_item_modal = true;
      this.is_edit_item_modal = false;
    },
    editMenuItemModal(item) {
      this.obj_menu_item = item;
      this.is_item_modal = true;
      this.is_edit_item_modal = true;
      this.menu_item_title = "Edit Item";
    },
    addMenuItemExtra() {
      this.obj_menu_item_extra = {};
      this.menu_item_extra_title = "Add Item Extra";
      this.is_item_extra_modal = true;
      this.is_edit_item_extra_modal = false;
    },
    editMenuItemExtraModal(extra) {
      this.obj_menu_item_extra = extra;
      this.is_item_extra_modal = true;
      this.is_edit_item_extra_modal = true;
      this.menu_item_extra_title = "Edit Item Extra";
    }
  },
  mounted() {
    this.restaurantData = JSON.parse(localStorage.getItem("restaurantDetails"));
    this.restaurantId = this.restaurantData.id;
    this.Uuid = this.restaurantData.uuid;
    this.getRestaurantMenu();
    let permission = JSON.parse(localStorage.getItem("currentUser")).permission;
    this.permission = {
      is_add: permission.is_add,
      is_deleted: permission.is_deleted,
      is_edit: permission.is_edit,
      web_access_enabled: permission.web_access_enabled
    };
  }
};
</script>

<style scoped>
.menu-group-list {
  border: 2px solid black;
  margin-bottom: 2px;
  padding: 2px;
  background-color: #ffa68d;
}
</style>
