<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Restaurant Setting'">
        <template v-slot:preview>
          <div class="card card-custom no-box-shadow">
            <div class="card-body resturant-setting-vue p-0">
              <div
                class="d-flex flex-wrap justify-content-start restaurant-setting-menu"
              >
                <div v-for="item in menu" :key="item.id">
                  <button
                    :class="item.class"
                    @click="updateClass(item.id, item.path)"
                  >
                    {{ item.title }}
                  </button>
                </div>
                <div class="w-100 mt-4">
                  <RestaurantSettingGeneral
                    v-if="type === '/general_settings' || type === ''"
                  ></RestaurantSettingGeneral>
                  <Management v-if="type === '/management'"></Management>
                  <Menu v-if="type === '/menu'"></Menu>
                  <MenuGroup v-if="type === '/menu_group'"></MenuGroup>
                  <MenuCategory v-if="type === '/menu_category'"></MenuCategory>
                  <MenuItem v-if="type === '/menu_item'"></MenuItem>
                  <Allergens v-if="type === '/allergens'"></Allergens>
                  <MenuExtraTypes
                    v-if="type === '/menu_extra_types'"
                  ></MenuExtraTypes>
                  <MenuItemExtra
                    v-if="type === '/menu_item_extra'"
                  ></MenuItemExtra>
                  <Offers v-if="type === '/offers'"></Offers>
                  <ReservationSlots
                    v-if="type === '/reservation_slots'"
                  ></ReservationSlots>
                  <MenuManagement
                    v-if="type === '/menu_management'"
                  ></MenuManagement>
                </div>
              </div>
            </div>
          </div>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>

<script>
// import router from "../../router";
import KTCodePreview from "@/view/content/CodePreview.vue";
import RestaurantSettingGeneral from "./RestaurantSettingGeneral";
import Management from "./Management";
import Menu from "./Menu/TreeView";
import Allergens from "./Allergens";
import MenuGroup from "./MenuGroup";
import MenuCategory from "./MenuCategory";
import Offers from "./Offers";
import ReservationSlots from "./ReservationSlots";
import MenuExtraTypes from "./MenuExtraTypes";
import MenuItemExtra from "./MenuItemExtra";
import MenuItem from "./MenuItem";
import MenuManagement from "./MenuManagement";

export default {
  name: "RestaurantSettingMenu",
  data: function() {
    return {
      type: "",
      menu: [
        {
          id: 1,
          path: "/general_settings",
          title: "Basic Settings",
          class: "btn btn--menu active"
        },
        {
          id: 2,
          path: "/management",
          title: "Management",
          class: "btn btn--menu"
        },
        {
          id: 3,
          path: "/menu_management",
          title: "Menu Management",
          class: "btn btn--menu"
        },
        {
          id: 4,
          path: "/allergens",
          title: "Allergens",
          class: "btn btn--menu"
        },
        {
          id: 5,
          path: "/offers",
          title: "Offers",
          class: "btn btn--menu"
        },
        {
          id: 6,
          path: "/reservation_slots",
          title: "Reservation Slots",
          class: "btn btn--menu"
        }
      ]
    };
  },
  methods: {
    updateClass(id, path) {
      for (let i = 0; i < this.menu.length; i++) {
        this.menu[i].class = "btn btn--menu";
        if (this.menu[i].id === id) {
          this.menu[i].class = "btn btn--menu active";
          // console.log(path);
          this.type = path;
          // router.push({path: path});
        }
      }
    }
  },
  components: {
    KTCodePreview,
    RestaurantSettingGeneral,
    Management,
    MenuManagement,
    Menu,
    Allergens,
    MenuGroup,
    MenuCategory,
    Offers,
    ReservationSlots,
    MenuExtraTypes,
    MenuItemExtra,
    MenuItem
  }
};
</script>

<style scoped>
.no-box-shadow {
  box-shadow: none;
}
</style>
